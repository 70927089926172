export function NotFound() {
  return (
    <div className="bg-blue flex h-screen flex-col items-center justify-center">
      <div className="tracking-pn-3 text-skyblue pb-[2rem] text-[20rem] leading-[80%] md:text-[20rem]">
        404
      </div>
      <em className="text-base">We couldn't find this page!</em>
    </div>
  )
}
